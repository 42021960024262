<script setup>

import { ref, watch, onMounted } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
const settings = settingsStore();
import { appStateStore } from "@/stores/AppStateStore.js";
const app = appStateStore();
import { useMapTools } from "@/Composables/MapTools.js";
const { goTo, goToSelected } = useMapTools();
import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();
import { tasksStore } from "@/stores/TasksStore.js";

import { useDrawCompTasks } from "@/Composables/useDrawCompTasks.js";
const drawTasks = useDrawCompTasks();

import { XMarkIcon, TrophyIcon, ChevronDoubleLeftIcon,  ChevronDoubleRightIcon, FunnelIcon, Bars3Icon, ShareIcon } from '@heroicons/vue/24/outline';

var loading = ref(0);
var tasksLoading = ref(0);
var comp = ref(null);
var firstLoad = ref(true);
const tasks = tasksStore();

const props = defineProps(['slug']);

onMounted(() => {
	showTasks();
	drawTasks.zoomToTasks(tasks.activeTasks());
});


// anything that should happen once the first load of live data has arrived
watch(() => tasks.activeTasksCount(), (currentValue, oldValue) => {
	//console.log('tasks to load updated!');
	// if (currentValue.length==0)
	// {
	// 	//console.log('NO MORE TAKSS!');
	// 	clearTasks();
	// }
	showTasks();
	drawTasks.zoomToTasks(tasks.activeTasks());

});

// function hideTasks() {
// 	//console.log('Hiding all tasks');
// 	for (let i=0; i<app.tasks.length; i++)
// 	{
// 		app.tasks[i].show=false;
// 	}

// }



// function loadTasks() {
// 	// console.log('Loading tasks');
// 	// console.log(app.tasksToLoad);
// 	hideTasks();

// 	if (app.tasksToLoad.length==0) {
// 		// kill itself
// 		app.tasksToLoad = null;
// 		showTasks(); // redraw the no tasks
// 		return;
// 	}

// 	for (let i=0; i<app.tasksToLoad.length; i++)
// 	{
// 		// check if we already have this one loaded
// 		var result = app.tasks.filter(task => {
// 			//console.log('Checking ' + task.id + ' vs ' + app.tasksToLoad[i]);
// 			return task.id === app.tasksToLoad[i]
// 		});

// 		// console.log("result");
// 		// console.log(result);

// 		if (result.length>0) {
// 			result[0].show = true;
// 			// console.log('found a task, turning it on');
// 			// console.log(result);
// 		} else {
// 			// otherwise load it 
// 			tasksLoading.value++;
// 			axios.get('/api/tasks/' + app.tasksToLoad[i]).then(function(response) {
// 				// console.log('Loaded');
// 				tasksLoading.value--;
// 				let task = response.data.data;
// 				task.show=true;

// 				app.tasks.push(task);

// 				// last one, load tasks
// 				if (tasksLoading.value==0) showTasks();

// 			}).catch(function (error) {
// 				console.log(error);
// 				tasksLoading.value--;

// 				if (error?.response?.data?.message) {
// 					messages.error(error?.response?.data?.message);
// 				} else {
// 					messages.error("Failed to load task");
// 				}
// 			});
// 		}
// 	}
// 	showTasks();
// }

function closeTasks() {
	tasks.clearTasks();
}

function showTasks() {
	//console.log(tasks.activeTasks());
	if (tasks.activeTasks().length>0) {
		drawTasks.redrawTasks(tasks.activeTasks());
	}
}

// function copyUrl() {
// 	copyToClipboard(comp.value.url); 
// 	messages.success('Link to contest copied to clipboard');
// 	tippyCompRef.value.hide();
// }

</script>

<template>
	<div  
	@click="app.show='tasks-menu'" 
	:title="$t('contests-menu.contest-button')"  
	class="ui-pill pl-1 pr-1 py-1 self-center flex items-center gap-2 text-white bg-orange-700 w-full">

		<button  
		:title="$t('group-menu.close-group')" 
		@click.stop="closeTasks()"
		class="pill-button flex-shrink-0">
			<XMarkIcon class="size-4 text-orange-700" />
		</button>
		
		<ShareIcon class="w-5 h-5  flex-shrink-0 text-white opacity-40" />

		<span v-if="tasks.tasks" class="whitespace-nowrap overflow-hidden overflow-ellipsis  flex-grow flex items-center gap-2 justify-center" >
			{{tasks.activeTasksCount()}} {{ $t('tasks.tasks') }}
			<LoadingHorizontal v-if="tasks.tasksLoading>0"></LoadingHorizontal>
		</span>

		<button :title="$t('app.menu')"
		class="pill-button flex-shrink-0"
		@click="app.show='tasks-menu'">
			<Bars3Icon class="size-4 text-orange-700" />
		</button>


	</div>

</template>
