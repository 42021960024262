<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { sourcesStore } from "@/stores/SourcesStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";
import DownloadIcon from '@/Icons/DownloadIcon.vue';

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, ArrowUpOnSquareIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();
const sources = sourcesStore();

import { pointsStore } from "@/stores/PointsStore.js";
const points  = pointsStore();

import { tracksStore } from "@/stores/TracksStore.js";
const tracks  = tracksStore();

import { formatDateTimeFromStamp } from '../Helpers.js';

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

const selectedSources = ref({});
const counts = ref({});

const showAvinetErrors = ref(false);




function forceFileDownload(response, title) {
	const url = window.URL.createObjectURL(new Blob([response.data]))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', title)
	document.body.appendChild(link)
	link.click()
}

function sendToAviNet(url, data, title) {

}

function downloadWithAxios(url, data, title) {
	axios({
		method: 'post',
		url,
		responseType: 'arraybuffer',
		data: data
	})
	.then((response) => {
		forceFileDownload(response, title)
	})
	.catch(() => {
		messages.error(t('download.error-downloading'));
	})
}

function sendToAvinet() {
	let track = tracks.tracks[points.selected.key];

	let data = {
		'start': app.selectedRangeStart,
		'end': app.selectedRangeEnd,
		'key': points.selected.key,
		'sources': selectedSources.value,
		'average': settings.useMovingAverage,
		'filter': settings.useTrackFilter
	}

	axios({
		method: 'post',
		url: '/api/download/avinet',
		data: data
	})
	.then((response) => {
		messages.success('Send to AviNet Started');
		app.show=null;
	})
	.catch(() => {
		messages.error('Couldn\'t send to AviNet');
	})
}


function download() {
	let track = tracks.tracks[points.selected.key];

	let data = {
		'start': app.selectedRangeStart,
		'end': app.selectedRangeEnd,
		'key': points.selected.key,
		'sources': selectedSources.value,
		'average': settings.useMovingAverage,
		'filter': settings.useTrackFilter
	}

	let filename = points.selected.key + '-' + app.selectedRangeStart + '-' + app.selectedRangeEnd + '.igc';

	downloadWithAxios('/api/download/igc', data, filename);
}


function selectAll() {
	for (var i=0; i<sources.sources.length; i++) {
		//console.log(sources.sources[i].id);
		selectedSources.value[sources.sources[i].id]=true;
	}
}
function selectNone() {
	for (var i=0; i<sources.sources.length; i++) {
		selectedSources.value[sources.sources[i].id]=false;
	}
}


function sortedTypes() {
	return sources.sources.sort(function(x,y) {
		if (x.count > y.count) return -1;
		return 1;
	});
}


watch(() => tracks.tracksUpdated, (currentValue, oldValue) => {
	counts.value = tracks.countSourcesOfTrack(points.selected.key, app.selectedRangeStart, app.selectedRangeEnd);
});

onMounted(() => {
	selectAll();
	counts.value = tracks.countSourcesOfTrack(points.selected.key, app.selectedRangeStart, app.selectedRangeEnd);

	// filterBadData.value = settings.useTrackFilter;
	// useAverage.value = settings.useMovingAverage;
});

function avinetAvailable() {
	if (!app.user) return false;
	if (app.user?.avinet_key=='') return false;
	if (app.user?.avinet_key==null) return false;

	return true;
}
</script>



<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow ">{{ $t('download.download') }}</h2>

					<button title="close" class="round-button opacity-0" @click="app.show=null">
						<XMarkIcon class="w-6 h-6 " />
					</button>
				</div>



				<div class="flex flex-col overflow-y-scroll px-4 pb-8 pt-4">
					<div class="flex flex-col gap-6 w-full">

						<h3 class="menu-section-title">{{ $t('download.from') }}</h3>

						<div class="menu-section ">

							<div class="menu-row text-center justify-center flex-col">
								<div class="flex flex-wrap items-center gap-2">
									{{formatDateTimeFromStamp(app.selectedRangeStart)}} 
									<ArrowRightIcon class="size-4"></ArrowRightIcon>
									{{formatDateTimeFromStamp(app.selectedRangeEnd)}}
								</div>

								<div class="caption">
									{{ $t('download.from-description') }}
								</div>
							</div>
						</div>



						<div class="menu-section ">
<!-- 
							<div class="menu-row ">

								<div class="flex  gap-3 w-full">

									<div>{{$t('download.file-type')}}</div>

									<label for="filetype-igc" class="whitespace-nowrap flex items-center gap-2  ">
										<input id="filetype-igc" type="radio" class=" opacity-60" checked disabled>
										<div class="flex-grow  ">IGC</div>
									</label>

									<div class="caption flex-grow text-right">
										{{$t('download.igc-description')}}
									</div>
								</div>

							</div> -->

							<div class="menu-row ">


								<div class="flex  gap-3 w-full">


									<label for="useaverage" class="whitespace-nowrap flex items-center gap-2  ">
										<input id="useaverage" type="checkbox" v-model="settings.useMovingAverage" >
										<div class="flex-grow ">{{ $t('chart.use-moving-average') }}</div>
									</label>

								</div>

							</div>
							<div class="menu-row ">


								<div class="flex  gap-3 w-full">

									<label for="filterBadData" class="whitespace-nowrap flex items-center gap-2  ">
										<input id="filterBadData" type="checkbox" v-model="settings.useTrackFilter" >
										<div class="flex-grow ">{{ $t('chart.filter-bad-data') }}</div>
									</label>

								</div>

							</div>

						</div>

						<h3 class="menu-section-title">
							{{$t('download.sources')}}
						</h3>

						<div class="menu-section ">

							<template v-for="source in sources.sources" :key="source.id">
								<div class="menu-row" v-if="source.show && counts[source.id]>0">
									
									<label 
										:for="'source-'+ source.id" 
										class="whitespace-nowrap flex items-center gap-2 w-full">
										<input 
											v-model="selectedSources[source.id]"

											:id="'source-'+ source.id" 
											type="checkbox" 
											:value="true"
											class="">
										<div class="flex-grow">{{source.name}}</div>

										<div>{{counts[source.id]}} {{$t('download.points')}}</div>
									</label>

								</div>
							</template>

						</div>


					</div>

				</div>

				<div class="px-4 pt-2 pb-6 flex flex-wrap items-center gap-4 justify-center">
					<button class="menu-button menu-row-clickable  flex items-center gap-2 !px-4 justify-center" @click="download()">
						<DownloadIcon class="size-5"></DownloadIcon>
						{{ $t('download.download') }} IGC
					</button>

					<button class="menu-button menu-row-clickable flex items-center gap-2 !px-4 justify-center" @click="sendToAvinet()" v-if="avinetAvailable()">
						<ArrowUpOnSquareIcon class="size-5"></ArrowUpOnSquareIcon>
						Send to AviNet
					</button>

					<button  class="menu-button menu-row-clickable flex items-center gap-2 !px-4 justify-center " @click="showAvinetErrors=true;" v-if="!avinetAvailable()">
						<ArrowUpOnSquareIcon class="size-5"></ArrowUpOnSquareIcon>
						Send to AviNet
					</button>

					<div v-if="showAvinetErrors" class="bg-red-800 text-white flex flex-col gap-2 p-2 rounded-lg">
						<div v-if="!app.user">
							<a href="/login" class="link !text-white">Login Required</a>
						</div>
						<div v-if="app.user?.avinet_key==null || app.user?.avinet_key==''">
							<a href="/user/profile" class="link !text-white">Avinet API Key</a> is required.
						</div>
					</div>

				</div>


			</div>


		</div>
	</div>

</template>