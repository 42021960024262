<script setup>
import { ref, computed } from 'vue';
import { appStateStore } from "@/stores/AppStateStore.js";
	
import { copyToClipboard } from '../Helpers.js';

import { Bars3Icon, ArrowUpOnSquareIcon, UserPlusIcon, UserMinusIcon, BellAlertIcon, CheckIcon, LinkIcon } from "@heroicons/vue/24/outline";
import DownloadIcon from '@/Icons/DownloadIcon.vue';
import SportsTrackLiveButton from '@/MapComponentsLayers/SportsTrackLiveButton.vue';

import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import { pointsStore } from "@/stores/PointsStore.js";
const points  = pointsStore(); 

import { messagesStore } from "@/stores/MessagesStore.js";
const messages = messagesStore();

import { settingsStore } from "@/stores/SettingsStore.js";
const settings = settingsStore();

const tippyRef = ref(null);
const app = appStateStore();

const copiedLink = ref(false);

const point = computed(() => {
	// console.log('point computed');
	// console.log(app.hoveringOnChart);
	// console.log(app.hoverPoint);
	// console.log(points.selected);
	if (app.hoveringOnChart && app.hoverPoint) return app.hoverPoint;
	return points.selected;
});

function copy(target) {
	copyToClipboard(calcUrl()); 
	messages.success('Link copied to clipboard');
	copiedLink.value = true;
	setTimeout(function (target) {
		copiedLink.value = false;
	}, 2000, target)
}

function calcUrlParams() {

	var string = "";
	var params = []; // array to put params into
	if (app.selected!==null) {

		if (app.getHashTag()) {
			return app.getHashTag();
		}

		if (points.selected.rego) {
			params.push('a=' + points.selected.rego);
		} else {
			params.push('k=' + app.selected);
		}
	}
	for (var i=0; i<params.length; i++) {
		if (i==0) string += "?" + params[i];
		else string += "&" + params[i];
	}

	return string;
}

function calcUrl(withProtocol = true) {
	var string = "";

	if (withProtocol) string = window.location.protocol + "//";
	string = string + window.location.host + '/';

	string = string + calcUrlParams();

	return string;
}


function clickDownloadLink() {
	// needs the chart open to get size
	if (settings.showChart==false) settings.showChart=true;
	app.show='download-menu'; 
	tippyRef.value.hide();

}
</script>

<template>

<tippy ref="tippyRef" trigger="click" :interactive="true" theme="puretrack" placement="bottom" :distance="5" class="" zIndex="999">

	<div class="flex items-center gap-1 btn-clear">
		<Bars3Icon class="size-5"></Bars3Icon> 
		<div>{{ $t('app.menu') }}</div>
	</div>

	<template #content>

		<div class="text-black rounded-xl pointer-events-auto flex flex-col shadow-2xl bg-gray-100 dark:bg-gray-800">

			<button
				class="p-2 menu-row menu-row-clickable"
				@click="copy(); tippyRef.hide()">
				<LinkIcon class="size-5 min-w-5" v-show="!copiedLink" />
				<CheckIcon class="size-5 min-w-5 text-green-600" v-show="copiedLink" />
				<div>{{ $t('selected.copy-link') }}</div>
			</button>

			<button class="p-2 menu-row menu-row-clickable" 
			@click="clickDownloadLink()"
			:title="$t('download.download')">
				<DownloadIcon class="size-5 opacity-60"></DownloadIcon>
				{{$t('download.download')}}
			</button>

			<button class="p-2 menu-row menu-row-clickable" 
			@click="clickDownloadLink()"
			:title="$t('send-to-avinet')">
				<img src="/images/source-icons/avinet.png" class="size-5" alt="AviNet Logo">
				{{ $t('download.send-to-avinet') }}
			</button>

			<div class="p-2 menu-row menu-row-clickable" >
				<div class="size-5 rounded-full bg-cover" style="background-image: url(/images/source-icons/sports-track-live.png)"></div>
				<SportsTrackLiveButton @finished="tippyRef.hide();"></SportsTrackLiveButton>
			</div>

			<button class="p-2 menu-row menu-row-clickable" 
			@click="app.show='share-menu'; tippyRef.hide()"
			:title="$t('legend.toggle-share')">
				<ArrowUpOnSquareIcon class="size-5 opacity-60" />
				{{$t('selected.share')}}
			</button>

			<button  class="p-2 menu-row menu-row-clickable"
			@click="app.show='alerts-menu'; tippyRef.hide()"
			:title="$t('alerts.alerts')">
				<BellAlertIcon class="size-5 opacity-60" />
				{{$t('alerts.alerts')}}
				<div class="bg-white bg-opacity-50 text-black rounded-full w-5 " v-if="app.alerts.length">{{app.alerts.length}}</div>
			</button>

			<button
				v-if="app.groupLoaded && !groups.isInGroup(point.key)" 
				@click="groups.toggleGroup(point); tippyRef.hide()"
				class="p-2 menu-row menu-row-clickable"
				:title="$t('selected.add-to-group')" >
				<UserPlusIcon class="size-5 opacity-60" />
				{{$t('selected.add-to-group')}}
			</button>

			<button 
				v-if="app.groupLoaded && groups.isInGroup(point.key)" 
				@click="groups.toggleGroup(point); tippyRef.hide()"
				class="p-2 menu-row menu-row-clickable"
				:title="$t('selected.remove-from-group')" >
				<UserMinusIcon class="size-5 opacity-60" />
				{{$t('selected.remove-from-group')}}
			</button>

		</div>
	</template>
</tippy>

</template>