<script setup>
import { ref, onMounted, watch } from 'vue';
import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { useMapTools } from "@/Composables/MapTools.js";
import { skySightStore } from "@/stores/SkySightStore.js";

import { useMeasureTool }  from "@/Composables/UseMeasureTool.js";
const measureTool = useMeasureTool();
import { measureToolStore }  from "@/stores/MeasureToolStore.js";
const measureToolData = measureToolStore();
const { goToSelected, hasFollows, goTo, followingLength } = useMapTools();

const app = appStateStore();
const settings = settingsStore();
const skysight = skySightStore();

import interact from 'interactjs'

const sidepanel = ref();

import AirspaceIcon from "@/Icons/AirspaceIcon.vue";
import RuleIcon from "@/Icons/RuleIcon.vue";
import TargetIcon from "@/Icons/TargetIcon.vue";

import { useDrawTargetTool }  from "@/Composables/UseDrawTargetTool.js";
const targetTool = useDrawTargetTool();

import { formatTimeFromStamp, renderDistance } from '../Helpers.js';

import { CogIcon, XMarkIcon, MagnifyingGlassIcon, AdjustmentsVerticalIcon, ArrowUpCircleIcon, Square3Stack3DIcon, PlusIcon, InformationCircleIcon, TrophyIcon, ListBulletIcon, ArrowRightIcon, ArrowLeftIcon, MoonIcon, SunIcon, UserIcon, ChevronLeftIcon, ChevronRightIcon, SparklesIcon, RocketLaunchIcon, ViewfinderCircleIcon, MapPinIcon, TagIcon, CircleStackIcon, PlayIcon, CloudIcon, PresentationChartLineIcon, LockClosedIcon, GlobeAsiaAustraliaIcon } from "@heroicons/vue/24/outline";

import { useI18n } from 'vue-i18n'
const { t } = useI18n()


var recentMenu = [
	{id: 15, label: t('layers-menu.15-label'), alt: t('layers-menu.15-alt'), pro: false},
	{id: 60, label:t('layers-menu.60-label'), alt: t('layers-menu.60-alt'), pro: false},
	{id: 360, label:t('layers-menu.360-label'), alt: t('layers-menu.360-alt'), pro: false},
	{id: 720, label:t('layers-menu.720-label'), alt: t('layers-menu.720-alt'), pro: true},
	{id: 1440, label: t('layers-menu.1440-label'), alt: t('layers-menu.1440-alt'), pro: true},
];

const tippyMeasureTool = ref();
const tippyTargetTool = ref();



// auto hide/close the measure tool info box
watch(() => measureToolData.measureMode, (currentValue, oldValue) => {
	if (currentValue==true) {
		tippyMeasureTool.value?.show();
	} else {
		//console.log('closing tippy');
		tippyMeasureTool.value?.hide();
	}
});


function toggleLabels() {
	if (settings.showLabels && settings.showSecondLine) {
		settings.showLabels = false;
		settings.showSecondLine = false;
		app.redrawLabels++;
	} else if (settings.showLabels && !settings.showSecondLine) {
		settings.showSecondLine = true;
		app.redrawLabels++;
	} else if (!settings.showLabels && !settings.showSecondLine) {
		settings.showLabels = true;
		settings.showSecondLine = false;
		app.redrawLabels++;
	}
}


function stopAllTools() {
	measureTool.disableAddMeasure();
	targetTool.disablePlaceTarget();
}

function openMeasureTool() {

	// if already active, hide it
	if (measureToolData.measureMode) {
		tippyMeasureTool.value.hide();
		stopAllTools(); 
	} else {
		// otherwise open it and start adding points
		stopAllTools(); 
		measureTool.toggleMeasure();
	}
}

function toggleDarkMode() {
	if (settings.darkMode==null) settings.darkMode='light';
	else if (settings.darkMode=='light') settings.darkMode='dark';
	else if (settings.darkMode=='dark') settings.darkMode='light';
}

</script>


<template>

	<div class="flex gap-2 flex-wrap sm:max-w-[370px] m-2 pb-2">

		<!-- <button 
			:title="$t('quick.icons')"
			class="size-8 "
			:class="[settings.showIcons ? 'round-button-selected' : 'round-button']"
			@click="settings.showIcons = !settings.showIcons">
			<MapPinIcon class="size-4" />
		</button> -->

		<div class="flex flex-col gap-2 flex-wrap sm:max-w-[350px]">


			<div class="flex flex-wrap gap-x-4 gap-y-2">
				<tippy v-if="app.subscribed" ref="tippyMeasureTool" trigger="manual" :interactive="true" theme="puretrack" placement="bottom" :hideOnClick="false" :sticky="false" class="pointer-events-auto">

					<div class="flex gap-1 items-center cursor-pointer" @click="openMeasureTool()">
						<div 
							:title="$t('measure.measure-tool')"
							class="h-8 cursor-pointer items-center justify-center flex gap-2"
							:class="[measureToolData.measureMode ? 'round-button-selected' : 'round-button']"
							>
							<RuleIcon class="size-4" />

							<div v-if="measureToolData.measureDistance">
								{{renderDistance(measureToolData.measureDistance, settings.distanceUnits)}}


									<button :title="$t('app.close')" 
										class=" pill-button"
										:class="[measureToolData.measureMode ? 'pill-button-selected' : 'round-button']" 
										 @click.stop="measureTool.clearMeasure(); tippyMeasureTool.hide(); stopAllTools();">
										<XMarkIcon  class="size-4" />
									</button>


							</div>

						</div>
						<div class="text-sm">{{ $t('measure.measure-tool') }}</div>
					</div>


					<template #content >

						<div class="flex flex-col gap-2 pointer-events-auto  text-black">

							<div 
								class="px-2 py-1  mx-1 mt-1 rounded bg-blue-800 text-white flex gap-1 items-center text-sm">
								<InformationCircleIcon class="size-5" />
								{{ $t('measure.tap-map-to-position') }}
							</div>

							<div v-if="measureToolData.measureMode" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="measureTool.toggleMeasure(); tippyMeasureTool.hide();">
								{{ $t('measure.finished') }}
							</div>


							<div v-if="measureToolData.measureDistance" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="measureTool.clearMeasure(); measureTool.toggleMeasure(); tippyMeasureTool.hide(); ">
								{{ $t('measure.clear-measuring-tape') }}
							</div>

						</div>

					</template>
				</tippy>




				<tippy v-if="app.subscribed" ref="tippyTargetTool" trigger="click" :interactive="true" theme="puretrack" placement="bottom" :hideOnClick="true" :sticky="true" class="pointer-events-auto">
					<div class="flex gap-1 items-center cursor-pointer"
					@click="stopAllTools(); if(!targetTool.hasTarget()) targetTool.togglePlaceTarget();">
						<button 
							:title="$t('layers-menu.target-tool')"
							class="size-8 flex items-center justify-center"
							:class="[measureToolData.targetMode ? 'round-button-selected' : 'round-button']"
							>

							<TargetIcon class="size-6" />
						</button>
						<div class="text-sm">{{ $t('layers-menu.target-tool') }}</div>
					</div>

					<template #content>
						<div class="flex flex-col gap-2 pointer-events-auto  text-black">
							
							<div v-if="!measureToolData.targetMode" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="targetTool.togglePlaceTarget();">
								{{ $t('target.place-target') }}
							</div>

							<div v-if="measureToolData.targetMode" 
								class="px-2 py-1 mx-1 mt-1 rounded bg-blue-800 text-white flex gap-1 items-center text-sm">
								<InformationCircleIcon class="size-5" />
								{{ $t('target.click-map-to-position-target') }}
							</div>

							<div v-if="measureToolData.targetMode" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="targetTool.togglePlaceTarget();  tippyTargetTool.hide();">
								{{ $t('app.cancel') }}
							</div>

							<div v-if="targetTool.hasTarget()" class="px-2 py-1 cursor-pointer hover:bg-gray-200" @click="targetTool.clearTarget(); tippyTargetTool.hide(); ">
								{{ $t('target.clear-target') }}
							</div>

							<div v-if="targetTool.hasTarget()" class="px-2 py-1 cursor-pointer hover:bg-gray-200" 
								@click="targetTool.goToTarget(); tippyTargetTool.hide();  ">
								{{ $t('target.go-to-target') }}
							</div>

						</div>
					</template>
				</tippy>


				
				<button class="flex gap-1 items-center cursor-pointer" 
				@click="toggleLabels()">
					<div 
					:title="$t('quick.labels')"
					class="size-8 "
					:class="[settings.showLabels ? 'round-button-selected' : 'round-button']"
					>
					<TagIcon class="size-4" />
					</div>
					<div class="text-sm" >{{ $t('quick.labels') }}</div>
				</button>

				<button class="flex gap-1 items-center cursor-pointer" 
				@click="settings.showLocations = !settings.showLocations">
					<div 
					:title="$t('quick.points-of-interest')"
					class="size-8 "
					:class="[settings.showLocations ? 'round-button-selected' : 'round-button']"
					>
					<img src="/images/icons/runway.png" class="w-6">
					</div>
					<div class="text-sm" >{{ $t('quick.points-of-interest') }}</div>
				</button>


				<button class="flex gap-1 items-center cursor-pointer" 
				v-if="app.subscribed"
				@click="settings.showThermals = !settings.showThermals">
					<div 
					:title="$t('quick.climb-indicators')"
					class="size-8 !p-1 items-center justify-center"
					:class="[settings.showThermals ? 'round-button-selected' : 'round-button']"
					>
					<img src="/images/icons/spiral7.png"  v-show="settings.darkMode=='light'"  class="w-5 h-5 mx-auto">
					<img src="/images/icons/spiral7.png"  v-show="settings.darkMode=='dark'"  class="w-5 h-5 mx-auto">
					</div>
					<div class="text-sm">{{ $t('quick.climb-indicators') }}</div>
				</button>

				<button class="flex gap-1 items-center cursor-pointer"
				v-if="app.subscribed"
				@click="settings.showThermalHeights = !settings.showThermalHeights">
					<div 
					:title="$t('quick.climb-heights')"
					class="size-8 !p-1 items-center justify-center"
					:class="[settings.showThermalHeights ? 'round-button-selected' : 'round-button']"
					>
					<img src="/images/hex-grid.png" class="w-5 h-5 mx-auto">
					</div>
					<div class="text-sm">{{ $t('quick.climb-heights') }}</div>
				</button>


				<button class="flex gap-1 items-center cursor-pointer"
				v-if="app.subscribed"
				@click="settings.showWeatherStations = !settings.showWeatherStations">
					<div 
					:title="$t('quick.live-wind')"
					class="size-8 !p-1 items-center justify-center"
					:class="[settings.showWeatherStations ? 'round-button-selected' : 'round-button']"
					>
					<img src="/images/icons/wind5.png" class="w-8 mt-1 mx-auto relative">
					</div>
					<div class="text-sm">{{ $t('quick.live-wind') }}</div>
				</button>


				<button class="flex gap-1 items-center cursor-pointer"
				v-if="app.subscribed"
				@click="settings.showMountainsLayer = !settings.showMountainsLayer">
					<div 
					:title="$t('quick.live-wind')"
					class="size-8 !p-1 items-center justify-center"
					:class="[settings.showMountainsLayer ? 'round-button-selected' : 'round-button']"
					>
						<GlobeAsiaAustraliaIcon class="w-6"></GlobeAsiaAustraliaIcon>
					</div>
					<div class="text-sm">{{ $t('layers-menu.mountains') }}</div>
				</button>


				<button class="flex gap-1 items-center cursor-pointer"
				@click="settings.showAirspace = !settings.showAirspace">
					<div 
					:title="$t('quick.airspace')"
					class="size-8 items-center justify-center"
					:class="[settings.showAirspace ? 'round-button-selected' : 'round-button']"
					>
					<AirspaceIcon  class="size-6" />
					</div>
					<div class="text-sm">{{ $t('quick.airspace') }}</div>
				</button>

				<button class="flex gap-1 items-center cursor-pointer"
				@click="settings.showRainRadar = !settings.showRainRadar">
					<div 
					:title="$t('layers-menu.rain-radar')"
					class="size-8 items-center justify-center"
					:class="[settings.showRainRadar ? 'round-button-selected' : 'round-button']"
					>
					<CloudIcon  class="size-4" />
					</div>
					<div class="text-sm">{{ $t('layers-menu.rain-radar') }}</div>
				</button>


				<button  v-if="app.skysightLoggedIn" class="flex gap-1 items-center cursor-pointer"
				@click="settings.showSkySight = !settings.showSkySight">
					<div 
					:title="$t('layers-menu.more-layers')"
					class="size-8 items-center justify-center"
					:class="[settings.showSkySight ? 'round-button-selected' : 'round-button']"
					style="background-image: url('/images/source-icons/skysight.png'); background-repeat: no-repeat; background-size: 70%; background-position: center"
					>
					<div class="size-4"></div>
					</div>
					<div class="text-sm">SkySight</div>
				</button>

				<button  v-if="app.skysightLoggedIn" class="flex gap-1 items-center cursor-pointer"
				@click="settings.showSkySightSat = !settings.showSkySightSat">
					<div 
					:title="$t('layers-menu.more-layers')"
					class="size-8 items-center justify-center !p-0.5"
					:class="[settings.showSkySightSat ? 'round-button-selected' : 'round-button']">
					<div class="size-7 rounded rounded-full" style="background-image: url('/images/map-icons/skysight-satellite.png'); background-repeat: no-repeat; background-size: 100%; background-position: center"></div>
					</div>
					<div class="text-sm">{{ $t('layers-menu.live-satellite') }}</div>
				</button>



				<button class="flex gap-1 items-center cursor-pointer"
				@click="app.show='layers-menu'">
					<div 
					:title="$t('layers-menu.more-layers')"
					class="size-8 items-center justify-center round-button"
					>
					<Square3Stack3DIcon  class="size-4" />
					</div>
					<div class="text-sm">{{ $t('layers-menu.more-layers') }}</div>
				</button>
			</div>



			<div class="flex flex-col gap-1">
				<div>
					{{ $t('layers-menu.hide-markers-older-than') }}
				</div>
				<div class="flex flex-wrap gap-1 items-center text-sm">
					<button v-for="item in recentMenu"
						:title="item.alt"
						:key="item.id"
						:disabled="!app.subscribed && item.pro"
						class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
						@click="settings.filterMaxAge = item.id; app.restartLoop=true;"
						:class="settings.filterMaxAge==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
							{{ item.label }}
							<LockClosedIcon class="h-4 w-4" v-show="!app.subscribed && item.pro" />
					</button>
				</div>

			</div>

			<div class="flex flex-col"  v-if="settings.showSkySight && app.skysightLoggedIn">
					
				<div >{{ $t('layers-menu.skysight-forecast') }}</div>

				<div class="flex flex-wrap gap-2 items-center" v-if="settings.showSkySight && app.skysightLoggedIn">

					<button v-for="item in skysight.layers"
						:title="item.name"
						:key="item.id"
						:disabled="!app.subscribed && item.pro"
						class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1 text-sm"
						@click="settings.skySightLayer = item.id;"
						:class="settings.skySightLayer==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
							{{ item.name }}
							<LockClosedIcon class="h-4 w-4" v-show="!app.subscribed && item.pro" />
					</button>
				</div>

				<div class="flex gap-2 items-center mx-2" v-if="settings.showSkySight">
					<label for="skysightOpacity">Opacity</label>
					<span class="text-gray-600 dark:text-gray-400 text-sm">Transparent</span>
					<input class="w-full" type="range" id="skysightOpacity" name="skysightOpacity" min=".1" max="1" step="0.1" v-model="settings.skySightForecastOpacity" />
					<span class="text-gray-600 dark:text-gray-400 text-sm">Solid</span>
					<div class="text-white bg-gray-600 rounded p-1 text-xs">
						{{settings.skySightForecastOpacity * 100}}%
					</div>
					
				</div>

			</div>


		</div>


	</div>

</template>