<script setup>
import { ref } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import LanguageMenuRow from "@/MapComponentsLayers/LanguageMenuRow.vue";

const app = appStateStore();
const settings = settingsStore();

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, TrophyIcon, UserGroupIcon, PlusCircleIcon, ArrowsRightLeftIcon, ShareIcon, ArrowPathIcon} from "@heroicons/vue/24/outline";

function reload() {
	location.reload();
}

</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex flex-col p-2 sm:px-8 sm:py-4 w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="Close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

					<div class="flex gap-4 w-fit  items-center mx-auto">
						<img src="/images/puretrack-logo.png" width="30">
						<h1 class="text-2xl text-gray-600 dark:text-gray-200"><span class="font-bold">Pure</span>Track<span class="font-bold">.io</span></h1>
					</div>

					<button title="Settings" class="round-button" @click="app.showBack.push('puretrack-menu'); app.show='settings-menu'">
						<CogIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="overflow-y-scroll px-4 pb-4 pt-6 flex flex-col gap-4">


					<div class="menu-section">


						<div class="menu-row menu-row-clickable" @click="reload()">
							<ArrowPathIcon class="menu-row-icon" /> 
							{{ $t('puretrack-menu.reload') }}
						</div>


						<div class="menu-row menu-row-clickable" @click="app.showBack.push('puretrack-menu');app.show='modes-menu'">

							<ArrowsRightLeftIcon class="menu-row-icon" />

							<h3 class="flex-grow">{{ $t('puretrack-menu.change-mode') }}</h3> 

							<button :title="$t('puretrack-menu.change-mode')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="app.showBack.push('puretrack-menu');app.show='load-contests-menu'">

							<TrophyIcon class="menu-row-icon" />

							<h3 class="flex-grow">{{ $t('puretrack-menu.contests') }}</h3> 

							<button :title="$t('puretrack-menu.contests')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="app.showBack.push('puretrack-menu');app.show='load-groups-menu'">

							<UserGroupIcon class="menu-row-icon" />

							<h3 class="flex-grow">{{ $t('puretrack-menu.groups') }}</h3> 

							<button :title="$t('puretrack-menu.groups')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>

						<div class="menu-row menu-row-clickable" @click="app.showBack.push('puretrack-menu');app.show='tasks-menu'">

							<ShareIcon class="menu-row-icon" />

							<h3 class="flex-grow">
								{{$t('tasks.tasks')}}
							</h3> 

							<button :title="'tasks'" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</div>

					</div>

					<div class="menu-section">

						<a href="https://radar.puretrack.io/" class="menu-row menu-row-clickable">

							

							<h3 class="flex-grow">PureTrack Radar</h3> 

						</a>

					</div>

					<div class="menu-section">

						<a href="/trackers" class="menu-row menu-row-clickable">

							<PlusCircleIcon class="menu-row-icon" />

							<h3 class="flex-grow">{{ $t('puretrack-menu.add-a-tracker') }}</h3> 

							<button :title="$t('puretrack-menu.add-a-tracker')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</a>

					</div>



					<div class="menu-section">


						<a href="https://www.youtube.com/channel/UCI8sTbKIMgatgUpT8PWr8sQ" class="menu-row menu-row-clickable cursor-pointer">
							<svg class="h-6 w-6" x="0" y="0" version="1.1" viewBox="0 0 71.412065 50">
						  <g id="g5" transform="scale(.58824)">
						    <path id="path7" fill="currentColor" fill-opacity="1" d="M118.9 13.3c-1.4-5.2-5.5-9.3-10.7-10.7C98.7 0 60.7 0 60.7 0s-38 0-47.5 2.5C8.1 3.9 3.9 8.1 2.5 13.3 0 22.8 0 42.5 0 42.5s0 19.8 2.5 29.2C3.9 76.9 8 81 13.2 82.4 22.8 85 60.7 85 60.7 85s38 0 47.5-2.5c5.2-1.4 9.3-5.5 10.7-10.7 2.5-9.5 2.5-29.2 2.5-29.2s.1-19.8-2.5-29.3z"/>
						    <path id="polygon9" fill="#fff" d="M80.2 42.5 48.6 24.3v36.4z"/>
						  </g>
						</svg>
							YouTube Training Videos
						</a>

						<a href="/news" :title="$t('app.news')" class="menu-row menu-row-clickable">
							{{ $t('app.news') }}
						</a>

						<a href="/about" :title="$t('puretrack-menu.about')" class="menu-row menu-row-clickable">
							{{ $t('puretrack-menu.about') }}
						</a>
						<a href="/help" :title="$t('puretrack-menu.help')" class="menu-row menu-row-clickable">
							{{ $t('puretrack-menu.help') }}
						</a>
						<a href="/help/faq" title="FAQ" class="menu-row menu-row-clickable">
							FAQ
						</a>
						<a href="/upgrade" :title="$t('puretrack-menu.upgrade')" class="menu-row menu-row-clickable" v-if="!app.fromAppStore">
							{{ $t('puretrack-menu.upgrade') }}
						</a>
						<a href="/subscription" :title="$t('puretrack-menu.start-trial')" class="menu-row menu-row-clickable" v-if="!app.fromAppStore">
							{{ $t('puretrack-menu.start-trial') }}
						</a>

						<a href="/release-notes" :title="$t('puretrack-menu.release-notes')" class="menu-row menu-row-clickable">
							{{ $t('puretrack-menu.release-notes') }}
						</a>
						<a class="menu-row menu-row-clickable " href="/terms">Terms &amp; Conditions</a>

					</div>



					<LanguageMenuRow></LanguageMenuRow>
					

					<div class="flex justify-between gap-4 text-sm">



						<a href="https://www.facebook.com/puretrack.io" class="menu-button menu-row-clickable text-xs w-1/3 flex gap-1 px-2 items-center justify-center cursor-pointer">
 <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
<path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
</svg>
							FaceBook
						</a>





						<a href="https://www.instagram.com/puretrack.io/" class="menu-button menu-row-clickable text-xs w-1/3 flex gap-1 px-2 items-center justify-center cursor-pointer">
							<svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
            				Instagram
						</a>


						<button class="menu-button menu-row-clickable w-1/3" @click="settings.showWelcomeScreen=true; app.show=null;">
							{{ $t('puretrack-menu.show-welcome') }}
						</button>
					</div>



					<div class="text-xs text-gray-600 dark:text-gray-200 p-4 leading-4 flex flex-col gap-1 text-center credits">
						<p>
							Map tiles by <a target="_top" rel="noopener" href="https://tile.openstreetmap.org/">OpenStreetMap tile servers</a>, under the <a target="_top" rel="noopener" href="https://operations.osmfoundation.org/policies/tiles/">tile usage policy</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a></p>

						<p>Maps &copy; <a href="https://www.mapbox.com">Mapbox</a>, &copy; <a href="https://carto.com">Carto</a> and <a href="https://openicgc.github.io">Open ICGC</a></p>

						<p>Airspace &amp; Aviation Data by <a target="_top" rel="noopener" href="https://www.openaip.net">OpenAIP</a></p>

						<p>Airports &amp; Airfields by  <a target="_top" rel="noopener" href="https://ourairports.com">OurAirports</a></p>

						<p>FLARM data by OGN <a href="http://glidernet.org">glidernet.org</a></p>

						<p>Skylines data from <a href="https://skylines.aero">skylines.aero</a></p>

						<p>ADSB data by <a href="https://adsb.lol">adsb.lol</a> and <a href="https://adsbhub.org">ADSBHub</a></p>

						<p>Contains information from  <a href="https://adsb.lol">adsb.lol</a>, which is made available here under the <a href="https://opendatacommons.org/licenses/odbl/1-0/">Open Database License</a> (ODbL).</p>

						<p>Weather data <a href="https://www.zephyrapp.nz">zephyrapp.nz</a> and <a href="https://federation.ffvl.fr/pages/opendata" >FFVL</a></p>

						<p>Rain radar by <a href="https://www.rainviewer.com/api.html">rainviewer.com</a></p>

						<p class="mb-2">&copy; <a href="http://pear.co.nz/">Pear Limited</a> {{new Date().getFullYear()}}. Designed in New Zealand</p>
					</div>



				</div>




			</div>
		</div>
	</div>

</template>